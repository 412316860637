import customAxios from "@/utils/customAxios.js";
import apiDomain from "@/config/api.js";
import externalComponent from "@/utils/external-component-beta.js";
import VueUploadMultipleImage from "vue-upload-multiple-image";

// Utils
import { addImage, deleteImage } from "@/utils/aws";

// Components
const FormInput = () => externalComponent("FormInput");

export default {
  data() {
    return {
      item: {
        name: "",
        lastName: "",
        email: "",
        phone: "",
        password: "",
        // hostVerified: false,
      },
      hostVerified: false,
      imagesChanged: false,
      images: [],
      awsFolder: "owners/",
      profiles: null,
      profilesDescription: null,
    };
  },

  props: {
    id: String,
    action: String,
  },

  components: {
    FormInput,
    VueUploadMultipleImage,
  },

  methods: {
    getItem: async function () {
      let item = null;
      const $this = this;

      this.$parent.toggleLoader(true);

      await customAxios
        .get(apiDomain + "/panel/owner/" + this.id)
        .then((resp) => (item = resp))
        .catch((err) => (item = false));

      setTimeout(function () {
        $this.$parent.toggleLoader(false);
      }, 800);

      if (!item) {
        this.$toast.error("Error en la consulta");
      } else {
        if (item.status == 204) {
          this.$toast.error("Error en la consulta");
        } else {
          this.item = item.data;

          if (item.data.avatar) {
            this.images.push({
              default: 1,
              highlight: 1,
              name: "prev",
              path: item.data.avatar,
            });
          }

          if (this.item.hasOwnProperty("hostVerified")) {
            this.hostVerified = this.item.hostVerified;
          }

          if (
            this.item.hasOwnProperty("profiles") &&
            this.item.profiles.length
          ) {
            this.profiles = this.item.profiles
              .map((profile) => profile.title)
              .join(", ");

            const profilesDescription = this.item.profiles.filter(
              (profile) => profile.description != null
            );

            this.profilesDescription = profilesDescription
              .map((profile) => profile.description)
              .join(",");
          }
        }
      }
    },

    saveItem: async function () {
      let item = null;

      this.$parent.toggleLoader(true);

      if (this.imagesChanged) {
        await this.imagesAWS();
      }

      if (this.action == "edit") {
        await customAxios
          .put(apiDomain + "/panel/owner/" + this.id, this.item)
          .then((resp) => (item = resp))
          .catch((err) => (item = false));
      } else {
        await customAxios
          .post(apiDomain + "/panel/owner/", this.item)
          .then((resp) => (item = resp))
          .catch((err) => (item = false));
      }

      this.$parent.toggleLoader(false);

      if (!item) {
        this.$toast.error("Error en la consulta");
      } else {
        if (item.status == 203) {
          this.$toast.error("El correo ya existe");
        } else {
          this.$toast.success("Guardado con éxito");
          this.$emit("toggle-modal", false);
        }
      }
    },

    saveChanges: function () {
      if (this.validateData()) {
        this.saveItem();
      }
    },

    imagesAWS: async function () {
      if (this.item.avatar) {
        await deleteImage(this.item.avatar);
      }

      this.item.avatar = await addImage(
        this.images[0],
        this.item.name,
        this.awsFolder
      );
    },

    imagesUpdated: function (formData, index, fileList) {
      let $this = this;
      this.imagesChanged = true;

      setTimeout(function () {
        $this.images = fileList;
      }, 500);
    },

    imageDeleted: function (index, done, fileList) {
      let $this = this;
      this.imagesChanged = true;

      setTimeout(function () {
        $this.images.splice(index, 1);
      }, 500);
    },

    validateData: function () {
      let success = true;
      const re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (this.item.name == "") {
        success = false;
        this.$toast.error("Nombre inválido.");
      } else if (this.item.lastName == "") {
        success = false;
        this.$toast.error("Appellido inválido.");
      } else if (this.item.email == "" || !re.test(this.item.email)) {
        success = false;
        this.$toast.error("Correo inválido.");
      }

      return success;
    },

    hostVerifiedCheck(e) {
      this.item.hostVerified = e.target.checked;
    },
  },

  mounted() {
    if (this.action == "edit") {
      this.getItem();
    }
  },
};
