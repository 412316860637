import externalComponent from "@/utils/external-component-beta.js";

import eventBus from "@/utils/eventBus";
import Loader from "@/components/Loader/Loader.vue";
import { startAuthorization, axiosInterceptor } from "@/utils/customAxios.js";

const Navbar = () => externalComponent("navbar");
const Sidebar = () => externalComponent("sidebar");

export default {
  name: "app",

  components: {
    Sidebar,
    Navbar,
    Loader,
  },

  data() {
    return {
      isLoading: false,
      items: [
        {
          name: "Dashboard",
          icon: "clipboard",
          route: "/dashboard",
        },
        {
          name: "Reservaciones",
          icon: "calendar",
          route: "/reservaciones",
        },
        {
          name: "Propiedades",
          icon: "home",
          route: "/propiedades",
        },
        {
          name: "Interiores",
          icon: "tv",
          route: "/interiores",
        },
        {
          name: "Exteriores",
          icon: "sun",
          route: "/exteriores",
        },
        {
          name: "Restricciones",
          icon: "slash",
          route: "/restricciones",
        },
        {
          name: "Propietarios",
          icon: "user",
          route: "/propietarios",
        },
        {
          name: "Creadores",
          icon: "user",
          route: "/creadores",
        },
        {
          name: "Tipos de perfil",
          icon: "briefcase",
          route: "/tipos-de-perfil",
        },
        {
          name: "Categorías",
          icon: "tag",
          route: "/categorias",
        },
        {
          name: "Categorías de uso",
          icon: "tag",
          route: "/categorias-de-uso",
        },
        {
          name: "Configuraciones",
          icon: "settings",
          route: "/configuraciones",
        },
      ],
      navbarAvatar: {
        image: require("@/assets/images/user-default.jpg"),
        name: this.$session.get("name"),
        type: this.$session.get("level"),
      },
      navbarItems: [
        {
          name: "Tu perfil",
          icon: "user",
        },
        {
          name: "Cerrar sesión",
          icon: "log-out",
          emit: "logout",
        },
      ],
      activeModule: null,
    };
  },

  methods: {
    toggleLoader: function (status) {
      this.isLoading = status;
    },

    logout: function () {
      this.$session.destroy();
      this.$router.push("/");
    },
  },

  mounted() {
    if (!this.$session.exists()) {
      this.$router.push("/");
    }

    // if(!this.$session.get("v") || this.$session.get("v") !== 1) {
    //   this.$session.destroy();
    //   this.$router.push('/');
    // }

    eventBus.$on("toggle-loader", (status) => {
      this.toggleLoader(status);
    });
  },

  beforeCreate() {
    if (!this.$session.exists()) {
      this.$router.push("/");
    } else {
      startAuthorization();
      axiosInterceptor();
    }
  },
};
