import customAxios from '@/utils/customAxios.js';
import apiDomain from '@/config/api.js';
import externalComponent from '@/utils/external-component.js';

// Components
const Toolbar = () => externalComponent('toolbar');
const Table = () => externalComponent('table');
const Modal = () => externalComponent('modal');
import ModalContent from '@/components/Categories/Modal/Modal.vue';

export default {
  name: 'categories',

  components: {
    Toolbar,
    Table,
    Modal,
    ModalContent
  },

  data() {
    return {
      toolbar: {
        title: 'Listado de Categorías',
        mainAction: {
          name: 'Agregar Categoría',
          emit: 'add-item'
        }
      },
      columns: [
        {
          name: 'Nombre'
        },
        {
          name: '',
          status: true
        }
      ],
      rows: [],
      modal: {
        'add': {
          title: 'Agregar Categoría',
          description: '',
          action: 'Agregar'
        },
        'edit': {
          title: 'Editar Categoría',
          description: '',
          action: 'Actualizar'
        }
      },
      modalVisible: false,
      modalAction: '',
      selectedId: null,
    }
  },

  methods: {
    getItems: async function () {
      let items = null;

      await customAxios
        .post(apiDomain + '/panel/category/filter')
        .then(resp => items = resp)
        .catch(err => items = false);

      if (!items) {
        this.$toast.error('Error en la consulta');
      } else if (items.status == 204) {
        this.rows = [];
      } else {
        this.rows = items.data.items;
      }
    },

    addItem: function () {
      this.modalAction = 'add';
      this.selectedId = null;
      this.modalVisible = true;
    },

    editItem: function (id) {
      this.modalAction = 'edit';
      this.selectedId = id;
      this.modalVisible = true;
    },

    singleDelete: function (id) {
      this.deleteConfirmation([id]);
    },

    deleteConfirmation: function (ids) {
      let msg = "¿Estás seguro de eliminar ";
      msg += (ids.length > 1) ? "estos elementos?" : "este elemento?";

      this.$confirm({
        message: msg,
        button: {
          no: 'No',
          yes: 'Sí'
        },
        callback: confirm => {
          if (confirm) {
            this.deleteItems(ids);
          }
        }
      })
    },

    deleteItems: async function (ids) {
      let response = null;

      this.toggleLoader(true);

      await customAxios
        .post(apiDomain + '/panel/category/delete', { items: ids })
        .then(resp => response = resp)
        .catch(err => response = false);

      this.toggleLoader(false);

      if (!response) {
        this.$toast.error('Error eliminando item');
      } else {
        if (response.status != 200) {
          this.$toast.error('Error eliminando item');
        } else {
          this.$toast.success('Item/s eliminado/s con éxito');
          this.getItems();
        }
      }
    },

    toggleModal: function (status) {
      this.modalVisible = status;

      if (!status) {
        this.getItems();

        if (this.modalAction == 'edit') {
          this.$router.push({ path: '/categorias' });
        }
      }
    },

    verifyUrlParams: function () {
      const id = this.$route.params.id || null;

      if (id) {
        this.selectedId = id;
        this.modalAction = 'edit';
        this.modalVisible = true;
      }
    }
  },

  watch: {
    $route() {
      this.verifyUrlParams();
    }
  },

  mounted() {
    this.verifyUrlParams();
    this.getItems();
  }
};
