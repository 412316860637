import customAxios from '@/utils/customAxios.js';
import apiDomain from '@/config/api.js';
import externalComponent from '@/utils/external-component.js';
import VueUploadMultipleImage from 'vue-upload-multiple-image';

// Utils
import { addImage, deleteImage } from '@/utils/aws';

// Components
import VoerroTagsInput from '@voerro/vue-tagsinput';
const FormInput = () => externalComponent("FormInput");

export default {
  data() {
    return {
      item: {
        title: '',
        cover: '',
        description: ''
      },
      subcategories: [],
      addedSubcategories: [],
      removedSubcategories: [],
      imagesChanged: false,
      images: [],
      awsFolder: 'use-categories/'
    }
  },

  props: {
    id: String,
    action: String
  },

  components: {
    FormInput,
    VueUploadMultipleImage,
    "tags-input": VoerroTagsInput
  },

  methods: {
    getItem: async function () {
      let item = null;
      const $this = this;

      this.$parent.toggleLoader(true);

      await customAxios
        .get(apiDomain + '/panel/useCategory/' + this.id)
        .then(resp => item = resp)
        .catch(err => item = false);

      setTimeout(function () {
        $this.$parent.toggleLoader(false);
      }, 800);

      if (!item) {
        this.$toast.error('Error en la consulta');
      } else {
        if (item.status == 204) {
          this.$toast.error('Error en la consulta');
        } else {
          this.item = item.data;
          this.subcategories = item.data.subcategories || [];

          if (item.data.cover) {
            this.images.push({
              default: 1,
              highlight: 1,
              name: "prev",
              path: item.data.cover
            });
          }
        }
      }
    },

    subcategoryAdded: function (tag) {
      if (!tag._id) {
        this.addedSubcategories.push(tag.title);
      }
    },

    subcategoryRemoved: function (tag) {
      if (tag._id) {
        this.removedSubcategories.push(tag._id);
      } else {
        const index = this.addedSubcategories.indexOf(tag.title);
        this.addedSubcategories.splice(index, 1);
      }
    },

    getSelectedSubcategories: function () {
      let arrayHelper = [];

      this.subcategories.forEach(element => {
        if (element._id) {
          arrayHelper.push(element._id);
        }
      });

      return arrayHelper;
    },

    saveItem: async function () {
      let item = null;
      let body = {};

      this.$parent.toggleLoader(true);

      if (this.imagesChanged) {
        await this.imagesAWS();
      }

      body.item = this.item;
      body.selectedSubcategories = this.getSelectedSubcategories();
      body.addedSubcategories = this.addedSubcategories;
      body.removedSubcategories = this.removedSubcategories;

      if (this.action == 'edit') {
        await customAxios
          .put(apiDomain + '/panel/useCategory/' + this.id, body)
          .then(resp => item = resp)
          .catch(err => item = false);
      } else {
        await customAxios
          .post(apiDomain + '/panel/useCategory/', body)
          .then(resp => item = resp)
          .catch(err => item = false);
      }

      this.$parent.toggleLoader(false);

      if (!item) {
        this.$toast.error('Error en la consulta');
      } else {
        if (item.status == 204) {
          this.$toast.error('Error en la consulta');
        } else {
          this.$toast.success('Guardado con éxito');
          this.$emit('toggle-modal', false);
        }
      }
    },

    saveChanges: function () {
      if (this.validateData()) {
        this.saveItem();
      }
    },

    imagesAWS: async function () {
      if (this.item.cover) {
        await deleteImage(this.item.cover);
      }

      this.item.cover = await addImage(this.images[0], this.item.title, this.awsFolder, 1920, true);
    },

    imagesUpdated: function (formData, index, fileList) {
      let $this = this;
      this.imagesChanged = true;

      setTimeout(function () {
        $this.images = fileList;
      }, 500)
    },

    imageDeleted: function (index, done, fileList) {
      let $this = this;
      this.imagesChanged = true;

      setTimeout(function () {
        $this.images.splice(index, 1);
      }, 500);
    },

    validateData: function () {
      let success = true;

      if (this.item.title == '') {
        success = false;
        this.$toast.error('Título inválido.');
      } else if (this.item.description == '') {
        success = false;
        this.$toast.error('Descripción inválida.');
      } else if (this.images.length == 0) {
        success = false;
        this.$toast.error('Selecciona una imagen.');
      }

      return success;
    }
  },

  mounted() {
    if (this.action == 'edit') {
      this.getItem();
    }
  }
}
