import customAxios from '@/utils/customAxios.js';
import apiDomain from '@/config/api.js'
import externalComponent from '@/utils/external-component-beta.js'

const Toolbar = () => externalComponent('toolbar')
const Table = () => externalComponent('table')
const Pagination = () => externalComponent('pagination');
const Modal = () => externalComponent('modal');
import ModalContent from '@/components/Creators/Modal/Modal.vue';

export default {
  name: 'Creators',

  components: {
    Toolbar,
    Table,
    Pagination,
    Modal,
    ModalContent
  },

  data() {
    return {
      toolbar: {
        title: 'Listado de Creadores',
        order: {
          name: 'Status',
          options: [
            { name: 'Todos', value: 'all' },
            { name: 'Verificado', value: true },
            { name: 'No verificado', value: false }
          ]
        },
        search: {
          name: 'Buscar por',
          placeholder: 'Nombre o Email'
        }
      },
      itemsData: {
        page: 1,
        itemsPerPage: 10,
        search: '',
        filters: {}
      },
      statusSelected: 'all',
      totalItems: 0,
      pagination: {
        currentPage: 1,
        totalPages: 1
      },
      columns: [
        {
          name: 'Nombre'
        },
        {
          name: 'Email'
        },
        {
          name: 'Teléfono'
        },
        {
          name: 'Status'
        }
      ],
      rows: [],
      modal: {
        'add': {
          title: 'Agregar Creador',
          description: '',
          action: 'Agregar'
        },
        'edit': {
          title: 'Editar Creador',
          description: '',
          action: 'Actualizar'
        }
      },
      modalVisible: false,
      modalAction: '',
      selectedId: null,
    }
  },

  methods: {
    getItems: async function () {
      let items;

      if (this.statusSelected != 'all') {
        this.itemsData.filters.verified = this.statusSelected;
      } else {
        this.itemsData.filters.verified = { $in: [true, false] };
      }

      await customAxios
        .post(apiDomain + '/panel/creator/filter', this.itemsData)
        .then(res => items = res)
        .catch(err => console.log(err));

      if (!items) {
        this.$toast.error('Error en la consulta');
      } else if (items.status == 204) {
        this.rows = [];
        this.totalItems = 0;
        this.pagination.totalPages = 1;
      } else {
        this.rows = items.data.items;
        this.pagination.totalPages = items.data.totalPages;
        this.totalItems = items.data.totalItems;
      }
    },

    changePage: function (val) {
      this.itemsData.page = val;
      this.getItems();
    },

    filterChanged: function () {
      if (this.pagination.currentPage == 1) {
        this.getItems();
      } else {
        this.pagination.currentPage = 1;
      }
    },

    searchToolbar: function (val) {
      this.itemsData.search = val;
      this.filterChanged();
    },

    orderToolbar: function (val) {
      this.statusSelected = val;
      this.filterChanged();
    },

    resendMail: async function (email) {
      let response = null;

      await customAxios
        .get(apiDomain + '/panel/creator/resendVerification/' + email)
        .then(resp => response = resp)
        .catch(err => console.log(err));

      if (!response) {
        this.$toast.error('Error en la consulta');
      } else if (response.status != 200) {
        this.$toast.error('Error en la consulta (' + response.status + ')');
      } else {
        this.$toast.success('Email reenviado');
      }
    },

    verifyUrlParams: function () {
      const id = this.$route.params.id || null;

      if (id) {
        this.selectedId = id;
        this.modalAction = 'edit';
        this.modalVisible = true;
      }
    },

    toggleModal: function (status) {
      this.modalVisible = status;

      if (!status) {
        this.getItems();

        if (this.modalAction == 'edit') {
          this.$router.push({ path: '/creadores' });
        }
      }
    },
  },

  computed: {
    currentPage() {
      return this.pagination.currentPage;
    }
  },

  watch: {
    currentPage(val) {
      this.changePage(val);
    },

    $route() {
      this.verifyUrlParams();
    }
  },

  mounted() {
    this.verifyUrlParams();
    this.getItems();
  }
}
