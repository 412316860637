import customAxios from "@/utils/customAxios.js";
import apiDomain from "@/config/api.js";
import externalComponent from "@/utils/external-component-beta.js";

const Card = () => externalComponent("card");
const CardIcon = () => externalComponent("cardIcon");
const FormInput = () => externalComponent("FormInput");
const FormButton = () => externalComponent("formButton");
import Multiselect from "vue-multiselect";

export default {
  name: "Settings",

  components: {
    Card,
    CardIcon,
    FormInput,
    FormButton,
    Multiselect,
  },

  data() {
    return {
      disabled: false,
      settingId: null,
      displayLocationPrice: true,
    };
  },

  methods: {
    async getSettings() {
      let settings = null;

      await customAxios
        .get(apiDomain + "/panel/setting")
        .then((resp) => (settings = resp))
        .catch((err) => (settings = false));

      if (settings) {
        if (settings.data.length) {
          this.settingId = settings.data[0].hasOwnProperty("_id")
            ? settings.data[0]._id
            : null;

          this.displayLocationPrice = settings.data[0].hasOwnProperty(
            "display_location_price"
          )
            ? settings.data[0].display_location_price
            : false;
        }
      }
    },

    async activateSetting(setting, type) {
      this.disabled = true;
      this.$parent.toggleLoader(true);

      const flag = setting == true ? "desactivado" : "activado";

      let confirmMessage = "";
      let errorMessage = "Ocurrió un error con la consulta";

      if (type == "prices") {
        confirmMessage = `Los precios de las propiedades ${setting ? "estarán ocultos" : "serán mostrados"
          }`;
      }

      this.$confirm({
        message: confirmMessage,
        button: {
          yes: "Continuar",
          no: "Cancelar",
        },
        callback: async (confirm) => {
          if (confirm) {
            let body = {};
            let response = null;

            if (type == "prices") {
              body.display_location_price = !setting;
            }

            const url = this.settingId
              ? `${apiDomain}/panel/setting/${this.settingId}`
              : `${apiDomain}/panel/setting`;

            await customAxios
              .post(url, body)
              .then((resp) => {
                response = resp;
              })
              .catch((err) => {
                errorMessage = err.response.data.hasOwnProperty("message")
                  ? err.response.data.message
                  : "Ocurrió un error con la consulta";

                response = false;
              });

            if (!response || response.status != 200) {
              this.$toast.error(errorMessage);
            } else {
              this.$toast.success("Información actualizada con éxito");

              await this.getSettings();
            }
          }
        },
      });

      this.$parent.toggleLoader(false);
      this.disabled = false;
    },
  },

  async beforeMount() {
    this.$parent.toggleLoader(true);

    this.$parent.toggleLoader(false);
  },

  async mounted() {
    this.$parent.toggleLoader(true);

    await this.getSettings();

    this.$parent.toggleLoader(false);
  },
};
