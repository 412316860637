import customAxios from "@/utils/customAxios.js";
import apiDomain from "@/config/api.js";
import externalComponent from "@/utils/external-component-beta.js";

// Components
const Toolbar = () => externalComponent("toolbar");
const Table = () => externalComponent("table");
const Pagination = () => externalComponent("pagination");
const Modal = () => externalComponent("modal");
import ModalContent from "@/components/Owners/Modal/Modal.vue";

export default {
  name: "owners",

  components: {
    Toolbar,
    Table,
    Pagination,
    Modal,
    ModalContent,
  },

  data() {
    return {
      toolbar: {
        title: "Listado de Propietarios",
        mainAction: {
          name: "Agregar Propietario",
          emit: "add-item",
        },
        order: {
          name: "Status",
          options: [
            { name: "Todos", value: "all" },
            { name: "Verificado", value: true },
            { name: "No verificado", value: false },
          ],
        },
        search: {
          name: "Buscar por",
          placeholder: "Nombre o Email",
        },
      },
      itemsData: {
        page: 1,
        itemsPerPage: 10,
        search: "",
        filters: {},
      },
      statusSelected: "all",
      totalItems: 0,
      pagination: {
        currentPage: 1,
        totalPages: 1,
      },
      columns: [
        {
          name: "Anfitrión Verificado",
          status: true,
        },
        {
          name: "Nombre",
        },
        {
          name: "Email",
        },
        {
          name: "Teléfono",
        },
        {
          name: "Status",
        },
        {
          name: "",
          status: true,
        },
      ],
      rows: [],
      modal: {
        add: {
          title: "Agregar Propietario",
          description: "",
          action: "Agregar",
        },
        edit: {
          title: "Editar Propietario",
          description: "",
          action: "Actualizar",
        },
      },
      modalVisible: false,
      modalAction: "",
      selectedId: null,
    };
  },

  methods: {
    getItems: async function () {
      let items = null;

      if (this.statusSelected != "all") {
        this.itemsData.filters.verified = this.statusSelected;
      } else {
        this.itemsData.filters.verified = { $in: [true, false] };
      }

      await customAxios
        .post(apiDomain + "/panel/owner/filter", this.itemsData)
        .then((resp) => (items = resp))
        .catch((err) => (items = false));

      if (!items) {
        this.$toast.error("Error en la consulta");
      } else if (items.status == 204) {
        this.rows = [];
        this.totalItems = 0;
        this.pagination.totalPages = 1;
      } else {
        this.rows = items.data.items;
        this.pagination.totalPages = items.data.totalPages;
        this.totalItems = items.data.totalItems;
      }
    },

    addItem: function () {
      this.modalAction = "add";
      this.selectedId = null;
      this.modalVisible = true;
    },

    editItem: function (id) {
      this.modalAction = "edit";
      this.selectedId = id;
      this.modalVisible = true;
    },

    singleDelete: function (id) {
      this.deleteConfirmation([id]);
    },

    deleteConfirmation: function (ids) {
      let msg = "¿Estás seguro de eliminar ";
      msg += ids.length > 1 ? "estos elementos?" : "este elemento?";

      this.$confirm({
        message: msg,
        button: {
          no: "No",
          yes: "Sí",
        },
        callback: (confirm) => {
          if (confirm) {
            this.deleteItems(ids);
          }
        },
      });
    },

    deleteItems: async function (ids) {
      let response = null;

      this.toggleLoader(true);

      await customAxios
        .post(apiDomain + "/panel/owner/delete", { items: ids })
        .then((resp) => (response = resp))
        .catch((err) => (response = false));

      this.toggleLoader(false);

      if (!response) {
        this.$toast.error("Error eliminando item");
      } else {
        if (response.status != 200) {
          this.$toast.error("Error eliminando item");
        } else {
          this.$toast.success("Item/s eliminado/s con éxito");
          this.getItems();
        }
      }
    },

    toggleModal: function (status) {
      this.modalVisible = status;

      if (!status) {
        this.getItems();

        if (this.modalAction == "edit") {
          this.$router.push({ path: "/propietarios" });
        }
      }
    },

    verifyUrlParams: function () {
      const id = this.$route.params.id || null;

      if (id) {
        this.selectedId = id;
        this.modalAction = "edit";
        this.modalVisible = true;
      }
    },

    changePage: function (val) {
      this.itemsData.page = val;
      this.getItems();
    },

    filterChanged: function () {
      if (this.pagination.currentPage == 1) {
        this.getItems();
      } else {
        this.pagination.currentPage = 1;
      }
    },

    searchToolbar: function (val) {
      this.itemsData.search = val;
      this.filterChanged();
    },

    orderToolbar: function (val) {
      this.statusSelected = val;
      this.filterChanged();
    },

    resendMail: async function (email) {
      let response = null;

      await customAxios
        .get(apiDomain + "/panel/creator/resendVerification/" + email)
        .then((resp) => (response = resp))
        .catch((err) => console.log(err));

      if (!response) {
        this.$toast.error("Error en la consulta");
      } else if (response.status != 200) {
        this.$toast.error("Error en la consulta (" + response.status + ")");
      } else {
        this.$toast.success("Email reenviado");
      }
    },

    singlehostVerified: function (owner) {
      this.hostVerifiedConfirmation(owner);
    },

    hostVerifiedConfirmation: function (owner) {
      const hostVerified = owner.hasOwnProperty("hostVerified")
        ? owner.hostVerified
        : false;

      const msg =
        hostVerified == false
          ? "¿Estás seguro de verificar al anfitrión?"
          : "¿Estás seguro de desactivar la verificación del anfitrión?";

      this.$confirm({
        message: msg,
        button: {
          no: "No",
          yes: "Sí",
        },
        callback: async (confirm) => {
          if (confirm) {
            await this.hostVerifiedItems(owner);
          }
        },
      });
    },

    async hostVerifiedItems(owner) {
      const hostVerified = owner.hasOwnProperty("hostVerified")
        ? owner.hostVerified
        : false;
      let item = null;

      this.$parent.toggleLoader(true);

      await customAxios
        .put(apiDomain + "/panel/owner/" + owner._id, {
          hostVerified: !hostVerified,
        })
        .then((resp) => (item = resp))
        .catch((err) => (item = false));

      this.$parent.toggleLoader(false);

      if (!item) {
        this.$toast.error("Error en la consulta");
      } else {
        this.$toast.success("Guardado con éxito");
      }

      await this.getItems();
    },
  },

  computed: {
    currentPage() {
      return this.pagination.currentPage;
    },
  },

  watch: {
    currentPage(val) {
      this.changePage(val);
    },

    $route() {
      this.verifyUrlParams();
    },
  },

  mounted() {
    this.verifyUrlParams();
    this.getItems();
  },
};
