import customAxios from "@/utils/customAxios.js";
import apiDomain from "@/config/api.js";
import externalComponent from "@/utils/external-component-beta.js";

// Components
const Toolbar = () => externalComponent("toolbar");
const Table = () => externalComponent("table");
const Modal = () => externalComponent("modal");
import ModalContent from "@/components/CreatorProfiles/Modal/Modal.vue";

export default {
  name: "categories",

  components: {
    Toolbar,
    Table,
    Modal,
    ModalContent,
  },

  data() {
    return {
      toolbar: {
        title: "Listado de Tipos de perfil",
        mainAction: {
          name: "Agregar Tipo de perfil",
          emit: "add-item",
        },
      },
      columns: [
        {
          name: "Titulo",
        },
        {
          name: "",
          status: true,
        },
      ],
      rows: [],
      totalItems: 0,
      itemsData: {
        page: 1,
        itemsPerPage: 10,
        search: "",
        filters: {},
      },
      modal: {
        add: {
          title: "Agregar Tipo de perfil",
          description: "",
          action: "Agregar",
        },
        edit: {
          title: "Editar Tipo de perfil",
          description: "",
          action: "Actualizar",
        },
      },
      modalVisible: false,
      modalAction: "",
      selectedId: null,
      pagination: {
        currentPage: 1,
        totalPages: 1,
      },
    };
  },

  methods: {
    getItems: async function () {
      let items = null;

      await customAxios
        .post(apiDomain + "/panel/creator-profiles/filter", this.itemsData)
        .then((resp) => (items = resp))
        .catch((err) => (items = false));

      if (!items) {
        this.$toast.error("Error en la consulta");
      } else if (items.status == 204) {
        this.rows = [];
        this.totalItems = 0;
        this.pagination.totalPages = 1;
      } else {
        this.rows = items.data.items;
        this.pagination.totalPages = items.data.totalPages;
        this.totalItems = items.data.totalItems;
      }
    },

    addItem: function () {
      this.modalAction = "add";
      this.selectedId = null;
      this.modalVisible = true;
    },

    editItem: function (id) {
      this.modalAction = "edit";
      this.selectedId = id;
      this.modalVisible = true;
    },

    singleDelete: function (id) {
      this.deleteConfirmation([id]);
    },

    deleteConfirmation: function (ids) {
      let msg = "¿Estás seguro de eliminar ";
      msg += ids.length > 1 ? "estos elementos?" : "este elemento?";

      this.$confirm({
        message: msg,
        button: {
          no: "No",
          yes: "Sí",
        },
        callback: (confirm) => {
          if (confirm) {
            this.deleteItems(ids);
          }
        },
      });
    },

    deleteItems: async function (ids) {
      let response = null;

      this.toggleLoader(true);

      await customAxios
        .post(apiDomain + "/panel/creator-profiles/delete", { items: ids })
        .then((resp) => (response = resp))
        .catch((err) => (response = false));

      this.toggleLoader(false);

      if (!response) {
        this.$toast.error("Error eliminando item");
      } else {
        if (response.status != 200) {
          this.$toast.error("Error eliminando item");
        } else {
          this.$toast.success("Item/s eliminado/s con éxito");
          this.getItems();
        }
      }
    },

    toggleModal: function (status) {
      this.modalVisible = status;

      if (!status) {
        this.getItems();

        if (this.modalAction == "edit") {
          this.$router.push({ path: "/tipos-de-perfil" });
        }
      }
    },

    verifyUrlParams: function () {
      const id = this.$route.params.id || null;

      if (id) {
        this.selectedId = id;
        this.modalAction = "edit";
        this.modalVisible = true;
      }
    },
  },

  watch: {
    $route() {
      this.verifyUrlParams();
    },
  },

  mounted() {
    this.verifyUrlParams();
    this.getItems();
  },
};
