import customAxios from '@/utils/customAxios.js';
import apiDomain from '@/config/api.js';
import externalComponent from '@/utils/external-component.js';
import moment from 'moment';

// Components
const FormInput = () => externalComponent("FormInput");
const ButtonSelect = () => externalComponent('buttonSelect');
const BadgeBase = () => externalComponent('badgeBase');
import Multiselect from 'vue-multiselect'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/es';

export default {
  props: {
    id: String,
    action: String
  },

  components: {
    FormInput,
    ButtonSelect,
    BadgeBase,
    Multiselect,
    DatePicker
  },

  data() {
    return {
      payLink: 'https:/pay.scouter.com.mx/',
      item: {
        reservationNumber: '',
        date: '',
        creationDate: '',
        startTime: '',
        endTime: '',
        totalTime: '',
        pricePerHour: '',
        propertyId: '',
        status: 'En Espera',
        discount: '',
        totalDiscount: '',
        total: '',
        chargeId: '',
        creator: [{
          name: '',
          lastName: ''
        }],
        guests: 0,
        description: ''
      },
      daysOfCreation: 0,
      discount: '',
      serviceFeePercentage: .043,
      properties: [],
      selectedProperty: {},
      startTimeOpt: {
        start: '06:00',
        step: '00:30',
        end: '23:00',
      },
      endTimeOpt: {
        start: '06:30',
        step: '00:30',
        end: '23:30',
      },
      multiselect: {
        selectLabel: "Presiona enter para seleccionar",
        selectedLabel: "Seleccionado",
        deselectLabel: "Presiona enter para eliminar",
        placeholder: "Selecciona una opción"
      },
      onHoldOptions: [
        {
          name: 'Enviar Mail',
          value: 'sended'
        },
        {
          name: 'Sin Disponibilidad',
          value: 'unavailable'
        }
      ],
      sendCancelledOptions: [
        {
          name: 'Cancelar reserva',
          value: 'cancelled'
        }
      ],

      // Summary
      fees: {
        service: .043,
        iva: .16
      },
      summary: {
        property: null,
        taxes: null,
        serviceFee: null,
        total: null,
        taxesPercentage: null,
        serviceFeePercentage: null
      },
    }
  },

  methods: {
    getFee: async function () {
      let fees = null, data = null;

      await customAxios
        .get(apiDomain + '/panel/fee')
        .then(resp => fees = resp)
        .catch(err => fees = false);

      if (fees && fees.status == 200) {
        this.fees = fees.data;
        this.summary.taxesPercentage = fees.data.iva;
        this.summary.serviceFeePercentage = fees.data.service;
      }
    },

    getItem: async function () {
      let item = null;

      await customAxios
        .get(apiDomain + '/panel/reservation/' + this.id)
        .then(resp => item = resp)
        .catch(err => item = false);

      if (!item || item.status == 204) {
        this.$toast.error('Error en la consulta');
      } else {
        item = item.data[0];
        item.date = moment(item.date).toDate();
        this.item = item;

        this.selectedProperty = {
          title: item.property[0].title,
          _id: item.property[0]._id
        }

        this.getDaysOfCreation();

        if (item.discount) {
          this.discount = item.discount;
        }
      }
    },

    getProperties: async function () {
      let properties;

      await customAxios
        .get(apiDomain + '/panel/property/')
        .then(resp => properties = resp)
        .catch(err => properties = false);

      if (properties.status == 200) {
        this.properties = properties.data;
      }
    },

    getDaysOfCreation: function () {
      const today = moment();
      const creation = moment(this.item.creationDate);

      this.daysOfCreation = today.diff(creation, 'days');
    },

    changeToUnavailable: async function () {
      let response = null;

      await customAxios.put(apiDomain + '/panel/reservation/status/unavailable/' + this.id)
        .then(resp => response = resp)
        .catch(err => response = false);

      if (response.status == 200) {
        this.$toast.success('Reserva actualizada con éxito');
        this.getItem();
      } else {
        this.$toast.error('Error en la consulta');
      }
    },

    changeToSended: async function () {
      let response = null;

      await customAxios.put(apiDomain + '/panel/reservation/status/sended/' + this.id)
        .then(resp => response = resp)
        .catch(err => response = false);

      if (response.status == 200) {
        this.$toast.success('Reserva actualizada con éxito');
        this.getItem();
      } else {
        this.$toast.error('Error en la consulta');
      }
    },

    changeToCancelled: async function () {
      let response = null;

      await customAxios.put(apiDomain + '/panel/reservation/status/cancelled/' + this.id)
        .then(resp => response = resp)
        .catch(err => response = false);

      if (response.status == 200) {
        this.$toast.success('Reserva actualizada con éxito');
        this.getItem();
      } else {
        this.$toast.error('Error en la consulta');
      }
    },

    confirmChange: function (status) {

      this.$confirm({
        message: "¿Estás seguro de cambiar el Status de esta reserva?",
        button: {
          no: 'No',
          yes: 'Sí'
        },
        callback: confirm => {
          if (confirm) {
            switch (status) {
              case 'available':
                this.changeToAvailable();
                break;
              case 'unavailable':
                this.changeToUnavailable();
                break;
              case 'sended':
                this.changeToSended();
                break;
              case 'cancelled':
                this.changeToCancelled();
                break;
              default:
                break;
            }
          }
        }
      })
    },

    saveChanges: async function () {
      let response;

      if (this.action != 'edit' && this.validateData()) {
        this.$parent.toggleLoader(true);
        this.item.summary = this.summary;

        await customAxios
          .post(apiDomain + '/panel/reservation', this.item)
          .then(resp => response = resp)
          .catch(err => response = false);

        this.$parent.toggleLoader(false);

        if (!response || response.status == 500) {
          this.$toast.error("Ocurrió un error al generar la reserva");
        } else {
          response = response.data;

          this.item.status = 'Enviada';
          this.item._id = response._id;
          this.item.creationDate = response.creationDate;
          this.item.reservationNumber = response.reservationNumber;
          this.action = 'edit';
          this.$toast.success("Reserva creada con éxito");
        }
      }
    },

    validateData: function () {
      let success = true;
      let mssg = "";
      const { date, startTime, endTime, propertyId } = this.item;

      if (!date) {
        success = false;
        mssg = "Por favor, selecciona una fecha";
      } else if (!propertyId) {
        success = false;
        mssg = "Por favor, selecciona una propiedad";
      } else if (!startTime || !endTime) {
        success = false;
        mssg = "Por favor, selecciona las horas de inicio y fin";
      }

      if (!success) {
        this.$toast.error(mssg);
      }

      return success;
    },

    statusType: function (status) {
      let status_type = '';

      switch (status) {
        case 'En Espera':
          status_type = 'warning';
          break;

        case 'Enviada':
          status_type = 'info';
          break;

        case 'Sin Disponibilidad':
          status_type = 'danger';
          break;

        case 'Confirmada':
          status_type = 'primary';
          break;

        case 'Checked Out':
          status_type = 'success';
          break;

        case 'Vencida':
          status_type = 'dark';
          break;

        case 'Cancelada':
          status_type = 'light';
          break;
      }

      return status_type;
    },

    disabledDates: function (date) {
      let disabled = false;
      const today = new Date();

      today.setHours(0, 0, 0, 0);

      if (date < today) {
        disabled = true;
      }

      return disabled;
    },

    limitTime: function (time, action) {
      const timeMoment = moment(time, 'HH:mm');

      if (action == 'add') {
        timeMoment.add(1, 'hours');
      } else {
        timeMoment.subtract(1, 'hours');
      }

      return timeMoment.format('HH:mm');
    },

    compareTimes: function (time1, time2, action) {
      let response = false;
      const time1Moment = moment(time1, 'HH:mm');
      const time2Moment = moment(time2, 'HH:mm');

      if (action == 'before') {
        response = time1Moment.isBefore(time2Moment);
      } else {
        response = time1Moment.isAfter(time2Moment);
      }

      return response;
    },

    startTimeChanged: function (time) {
      const limitStart = this.limitTime(time, 'add');
      this.endTimeOpt.start = limitStart;

      if (this.item.endTime) {
        if (this.compareTimes(this.item.endTime, limitStart, 'before')) {
          this.item.endTime = limitStart;
        }
      } else {
        this.item.endTime = limitStart;
      }

      this.calculateTotal();
    },

    propertyChanged: function (selectedOpt) {
      this.item.pricePerHour = selectedOpt.publicPricePerHour;
      this.item.propertyId = selectedOpt._id;
      this.calculateTotal();
    },

    calculateTotal: function () {
      const { service, iva } = this.fees;
      let { startTime, endTime, pricePerHour, discount } = this.item;
      let summaryProperty = 0, summaryTaxes = 0, summaryService = 0, summaryTotal = 0;
      let totalTime, totalDiscount = 0;

      if (startTime && endTime && pricePerHour) {
        pricePerHour = parseFloat(pricePerHour);

        startTime = moment(startTime, 'HH:mm');
        endTime = moment(endTime, 'HH:mm');

        totalTime = moment.duration(endTime.diff(startTime));
        totalTime = totalTime.asHours();

        if (!totalTime || totalTime == 0) {
          summaryProperty = pricePerHour;
        } else {
          summaryProperty = pricePerHour * totalTime;
        }

        summaryTaxes = summaryProperty * iva;
        summaryService = (summaryProperty + summaryTaxes) * service;
        summaryTotal = summaryProperty + summaryTaxes + summaryService;

        this.summary.property = summaryProperty.toFixed(2);
        this.summary.taxes = summaryTaxes.toFixed(2);
        this.summary.serviceFee = summaryService.toFixed(2);
        this.summary.total = summaryTotal.toFixed(2);
        this.item.total = summaryTotal.toFixed(2);

        if (discount) {
          totalDiscount = ((100 - discount) / 100) * summaryTotal;
          totalDiscount = totalDiscount.toFixed(2);
          this.item.totalDiscount = totalDiscount;
        } else if (this.item.totalDiscount) {
          this.item.totalDiscount = '';
        }
      }
    }
  },

  watch: {
    discount: function (val) {
      this.item.discount = val;
      this.calculateTotal();
    }
  },

  async mounted() {
    this.getFee();
    this.getProperties();

    if (this.action == 'edit') {
      this.getItem();
    }
  }
}
